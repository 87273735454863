var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    { staticClass: "col-md-12 public-playroom-index" },
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "4" } },
            [
              _c("SearchBar", {
                on: { searchParameterChange: _vm.onSearchParameterChange }
              })
            ],
            1
          ),
          _vm.ready
            ? _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c("SearchMap", {
                    attrs: {
                      searchedBookingType: _vm.searchedBookingType,
                      searchedCountOfGuests: Number(_vm.searchedCountOfGuests),
                      searchedCountOfHours: Number(_vm.searchedCountOfHours),
                      searchedCountOfNights: Number(_vm.searchedCountOfNights),
                      playrooms: _vm.playrooms,
                      maxDistance: Number(_vm.maxDistance),
                      markedPlayroomId: _vm.markedPlayroomId
                    }
                  }),
                  _vm.playroomCount != 0
                    ? _c("div", { staticClass: "playroom-count" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("pages.playroomSearch.playroomCount", {
                                playroomCount: _vm.playroomCount,
                                firstItemNumber: _vm.firstItemNumber,
                                lastItemNumber: _vm.lastItemNumber
                              })
                            ) +
                            " "
                        )
                      ])
                    : _vm.playroomCount == 0
                    ? _c("div", { staticClass: "playroom-count" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("pages.playroomSearch.noPlayroomFound", {
                                playroomCount: _vm.playroomCount
                              })
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _c("ToolBelt", {
                    attrs: { sortedBy: _vm.sortedBy },
                    on: { onSortByChange: _vm.onSortByChange }
                  }),
                  !_vm.ready ? _c("CSpinner") : _vm._e(),
                  _vm.ready
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.playrooms, function(playroom) {
                            return _c(
                              "div",
                              { key: playroom.id },
                              [
                                _c("PlayroomCard", {
                                  attrs: {
                                    "data-cy": "playroomCard",
                                    playroom: playroom,
                                    searchedBookingType:
                                      _vm.searchedBookingType,
                                    searchedCountOfGuests:
                                      _vm.searchedCountOfGuests,
                                    searchedCountOfHours:
                                      _vm.searchedCountOfHours,
                                    searchedCountOfNights:
                                      _vm.searchedCountOfNights,
                                    searchedCheckInTime: _vm.searchedCheckInTime
                                  },
                                  nativeOn: {
                                    mouseenter: function($event) {
                                      _vm.markedPlayroomId = playroom.id
                                    },
                                    mouseleave: function($event) {
                                      _vm.markedPlayroomId = ""
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "pages.playroomSearch.playroomCountFooter",
                                        {
                                          playroomCount: _vm.playroomCount,
                                          firstItemNumber: _vm.firstItemNumber,
                                          lastItemNumber: _vm.lastItemNumber
                                        }
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("CPagination", {
                                attrs: {
                                  pages: _vm.totalPages,
                                  activePage: _vm.currentPage
                                },
                                on: {
                                  "update:activePage": function($event) {
                                    _vm.currentPage = $event
                                  },
                                  "update:active-page": function($event) {
                                    _vm.currentPage = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }