var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card",
      class: { "pin-map": _vm.isPinned },
      staticStyle: { "margin-bottom": "15px" }
    },
    [
      _c(
        "CButton",
        {
          staticClass: "pin-btn",
          attrs: { color: "danger" },
          on: {
            click: function($event) {
              return _vm.onPinMap()
            }
          }
        },
        [
          _vm.isPinned
            ? _c("CIcon", { attrs: { name: "cisLockLocked" } })
            : _vm._e(),
          !_vm.isPinned
            ? _c("CIcon", { attrs: { name: "cisLockUnlocked" } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "GmapMap",
        {
          ref: "mapRef",
          staticStyle: { width: "100%", height: "300px" },
          attrs: {
            center: { lat: 51.2, lng: 10.4 },
            zoom: 5,
            options: {
              mapTypeControlOptions: _vm.mapTypeControlOptions,
              streetViewControl: false,
              gestureHandling: "greedy"
            },
            scrollwheel: false,
            infoWinOpen: true
          }
        },
        [
          _c(
            "gmap-info-window",
            {
              attrs: {
                options: _vm.infoOptions,
                position: _vm.infoWindowPos,
                opened: _vm.infoWinOpen
              },
              on: {
                closeclick: function($event) {
                  _vm.infoWinOpen = false
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "preview-container col-sm-12 col-md-12" },
                [
                  _vm.infoWinOpen
                    ? _c("PlayroomLocationPreview", {
                        attrs: {
                          "data-cy": "PlayroomLocationPreview",
                          playroom: _vm.playroomLocationPreview,
                          searchedBookingType: _vm.searchedBookingType,
                          searchedCountOfGuests: String(
                            _vm.searchedCountOfGuests
                          ),
                          searchedCountOfHours: String(
                            _vm.searchedCountOfHours
                          ),
                          searchedCountOfNights: String(
                            _vm.searchedCountOfNights
                          )
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._l(_vm.playroomsWithMarker, function(playroom, index) {
            return _c("GmapMarker", {
              key: "marker" + index,
              ref: "markers",
              refInFor: true,
              attrs: {
                position: { lat: playroom.latitude, lng: playroom.longitude },
                options: { markerId: playroom.id },
                label: playroom.label,
                title: playroom.title,
                clickable: true
              },
              on: {
                click: function($event) {
                  return _vm.toggleInfoWindow(playroom, index)
                }
              }
            })
          }),
          _vm._l(_vm.playroomsWithCircle, function(playroom, index) {
            return _c("GmapCircle", {
              key: "circle" + index,
              attrs: {
                center: {
                  lat: playroom.latitude,
                  lng: playroom.longitude
                },
                options: { strokeWeight: 0, fillOpacity: 0.2 },
                radius: parseFloat(playroom.addressRadius) * 1000,
                label: playroom.label,
                title: playroom.title,
                clickable: true
              }
            })
          }),
          _vm.maxDistance && _vm.maxDistance < 999
            ? _c(
                "span",
                [
                  _vm.userLocation.lat != 0
                    ? _c("GmapCircle", {
                        attrs: {
                          center: {
                            lat: _vm.userLocation.lat,
                            lng: _vm.userLocation.lng
                          },
                          radius: _vm.maxDistance * 1000,
                          options: { strokeWeight: 0, fillOpacity: 0.2 }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.maxDistance === null || _vm.maxDistance >= 999
            ? _c(
                "span",
                [
                  _vm.userLocation.lat != 0
                    ? _c("GmapMarker", {
                        attrs: {
                          position: {
                            lat: _vm.userLocation.lat,
                            lng: _vm.userLocation.lng
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }